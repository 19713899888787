import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { LOGIN_ACTION, REDIRECT_HOME_ACTION } from "../store/user.login.actions";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    isFailedtoLogin = false;
    loading = false;

    person: Observable<any>;
    constructor(private store: Store<{ person: { isLoggedIn: boolean } }>, private router: Router) { }

    ngOnInit(): void {
        this.person = this.store.select('person');
    }

    onSubmit(form: NgForm) {
        this.loading = true;
        if (!form.valid) {
            console.log("Invalid Form !!!")
            this.loading = false;
            if (form.controls.username.invalid || form.controls.password.invalid)
                this.isFailedtoLogin = true;
            return;
        }
        let username: string = form.value.username;
        let password: string = form.value.password;
        this.store.dispatch(new LOGIN_ACTION({ username: username, password: password }));
        form.resetForm();
        this.loading = false;
    }

}

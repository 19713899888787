import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RoadmapService } from 'src/app/services/roadmmap.service';
import { DataService } from "src/app/services/data.service";
import { ApplicationConstants } from 'src/app/services/app.constants';

@Component({
    templateUrl: './roadmap-response.component.html',
    styleUrls: ['./roadmap-response.component.css']
})
export class RoadmapResponseComponent implements OnInit {

    panelOpenState = true;
    settlementResponse: any;
    displayedColumns: string[] = ['monthYear', 'transactionType', 'amount', 'currentbalance', 'remark'];
    columnsList: string[] = ['monthYear', 'amount'];
    forecaster: any[];
    regionValuesNew: any[];
    regionValuesNewR2: any[];
    regionValuesNewR3: any[];
    accountsRankMap: Map<String, Number>;
    accountsRankMapR2: Map<String, Number>;
    accountsRankMapR3: Map<String, Number>;
    regionValues: Map<String, any>;
    regionValuesR2: Map<String, any>;
    regionValuesR3: Map<String, any>;
    lowestDifferntial: Map<String, Number>;
    lowestDifferntialR2: Map<String, Number>;
    lowestDifferntialR3: Map<String, Number>;
    firstSelectedAccount: Map<String, Number>;
    nextAccountsToSettle: Map<String, Number>;
    selectedAccount: Map<String, Number>;
    selectedAccountR2: Map<String, Number>;
    selectedAccountR3: Map<String, Number>;
    regionValuesKeys: any[];
    regionValuesKeysR2: any[];
    regionValuesKeysR3: any[];
    firstSelectionKey: any[];
    debtAccounts: any[];
    debtAccountsR2: any[];
    debtAccountsR3: any[];

    public colorWhite: string;
    public colorMildRed: string;

    constructor(private roadmapService: RoadmapService, private toastrService: ToastrService,
        private dataService: DataService) {
        console.log('I am RoadmapResponseComponent constructor');
    }

    ngOnInit() {
        this.colorWhite = ApplicationConstants.COLOR_WHITE;
        this.colorMildRed = ApplicationConstants.COLOR_MILD_RED
        this.dataService.currentMessage.subscribe(message => this.settlementResponse = message);
        if (this.settlementResponse != null && this.settlementResponse.forecaster != null) {
            var responseForecaster = this.settlementResponse.forecaster;
            if (responseForecaster != null) {
                this.forecaster = new Array();
                var index = 0;
                var start = 0;
                var end = 10;
                while (index < responseForecaster.length) {
                    if (index !== 0) {
                        start = index + 1;
                        end = index + 10;
                    }
                    console.log('start == ' + start + ' end == ' + end);
                    this.forecaster.push(responseForecaster.slice(index, end));
                    index += 10;
                }
            }

            if (this.settlementResponse.regionDetail.regionOne != undefined) {
                this.accountsRankMap = this.settlementResponse.regionDetail.regionOne.accountRanks;
                this.regionValues = this.settlementResponse.regionDetail.regionOne.regionValues;
                this.lowestDifferntial = this.settlementResponse.regionDetail.regionOne.lowestDifferntial;
                this.firstSelectedAccount = this.settlementResponse.regionDetail.regionOne.firstSelectedAccount;
                this.nextAccountsToSettle = this.settlementResponse.regionDetail.regionOne.nextAccountsToSettle;
                this.debtAccounts = this.settlementResponse.debtAccounts;

                if (Object.keys(this.firstSelectedAccount).length > 0) {
                    this.regionValuesKeys = Object.keys(this.firstSelectedAccount);
                }
                if (Object.keys(this.nextAccountsToSettle).length > 0) {
                    this.regionValuesKeys.push(Object.keys(this.nextAccountsToSettle));
                }

                this.regionValuesNew = new Array();
                if (this.regionValuesKeys != undefined && this.regionValuesKeys.length > 0) {
                    for (let key of this.regionValuesKeys) {
                        var account = this.debtAccounts.filter(x => x.accountId === key);
                        if (account != undefined && account.length > 0 && account[0].regionOneAvailableToSettle) {
                            var valObj = new Object;
                            var r1;
                            var r2;
                            var r3;
                            var rank;
                            var beginningBalance;
                            var financialReadiness;
                            r1 = account[0].r1SettlementAmount;
                            r1 = r1.toFixed(2);
                            r2 = account[0].r2SettlementAmount;
                            r2 = r2.toFixed(2);
                            r3 = account[0].r3SettlementAmount;
                            r3 = r3.toFixed(2);
                            var r = this.firstSelectedAccount[account[0].accountId] != null;
                            if (r === undefined) {
                                r = this.nextAccountsToSettle[account[0].accountId] != null;
                            }
                            var diff = this.lowestDifferntial[account[0].accountId];
                            diff = diff.toFixed(2);
                            rank = account[0].regionOneCreditorRank;
                            beginningBalance = account[0].beginningBalance;
                            beginningBalance = beginningBalance.toFixed(2);
                            financialReadiness = account[0].financialReadiness;
                            valObj = {
                                'accountId': account[0].accountId, 'rank': rank, 'r1SettlementAmount': r1, 'r2SettlementAmount': r2, 'r3SettlementAmount': r3,
                                'diff': diff, 'beginningBalance': beginningBalance, 'fr': financialReadiness, 'selected': r
                            };
                            this.regionValuesNew.push(valObj);
                        }
                    }
                }
            }


            if (this.settlementResponse.regionDetail.regionTwo != undefined) {
                this.accountsRankMapR2 = this.settlementResponse.regionDetail.regionTwo.accountRanks;
                this.regionValuesR2 = this.settlementResponse.regionDetail.regionTwo.regionValues;
                this.lowestDifferntialR2 = this.settlementResponse.regionDetail.regionTwo.lowestDifferntial;
                this.selectedAccountR2 = this.settlementResponse.regionDetail.regionTwo.selectedAccount;
                this.debtAccountsR2 = this.settlementResponse.debtAccounts;
                this.regionValuesKeysR2 = Object.keys(this.selectedAccountR2);
                this.regionValuesNewR2 = new Array();

                for (let key of this.regionValuesKeysR2) {
                    var account = this.debtAccountsR2.filter(x => x.accountId === key);
                    if (account != undefined && account.length > 0 && account[0].regionTwoAvailableToSettle) {
                        var valObj = new Object;
                        var r2;
                        var r3;
                        var rank;
                        var beginningBalance;
                        var financialReadiness;
                        r2 = account[0].r2SettlementAmount;
                        r2 = r2.toFixed(2);
                        r3 = account[0].r3SettlementAmount;
                        r3 = r3.toFixed(2);
                        var r = this.selectedAccountR2[account[0].accountId] != null;
                        var diff = this.lowestDifferntialR2[account[0].accountId];
                        diff = diff.toFixed(2);
                        rank = account[0].regionTwoCreditorRank;
                        beginningBalance = account[0].beginningBalance;
                        beginningBalance = beginningBalance.toFixed(2);
                        financialReadiness = account[0].financialReadiness;
                        valObj = {
                            'accountId': account[0].accountId, 'rank': rank, 'r2SettlementAmount': r2, 'r3SettlementAmount': r3,
                            'diff': diff, 'beginningBalance': beginningBalance, 'fr': financialReadiness, 'selected': r
                        };
                        this.regionValuesNewR2.push(valObj);
                    }
                }
            }

            //Region 3

            if (this.settlementResponse.regionDetail.regionThree != undefined) {
                this.lowestDifferntialR3 = this.settlementResponse.regionDetail.regionThree.lowestDifferential;
                this.selectedAccountR3 = this.settlementResponse.regionDetail.regionThree.selectedAccount;
                this.debtAccountsR3 = this.settlementResponse.debtAccounts;
                this.regionValuesNewR3 = new Array();
                this.regionValuesKeysR3 = Object.keys(this.selectedAccountR3);
                for (let key of this.regionValuesKeysR3) {
                    var account = this.debtAccountsR3.filter(x => x.accountId === key);
                    if (account != undefined && account.length > 0 && account[0].regionThreeAvailableToSettle) {
                        var valObj = new Object;
                        var r2;
                        var r3;
                        var rank;
                        var beginningBalance;
                        var financialReadiness;
                        r2 = account[0].r3FirstSettlementAmount;
                        r2 = r2.toFixed(2);
                        r3 = account[0].r3NextSettlementAmount;
                        r3 = r3.toFixed(2);
                        var r = this.selectedAccountR3[account[0].accountId] != null;
                        var diff = this.lowestDifferntialR3[account[0].accountId];
                        diff = diff.toFixed(2);
                        rank = account[0].regionThreeCreditorRank;
                        beginningBalance = account[0].beginningBalance;
                        beginningBalance = beginningBalance.toFixed(2);
                        financialReadiness = account[0].financialReadiness;
                        valObj = {
                            'accountId': account[0].accountId, 'rank': rank, 'r2SettlementAmount': r2, 'r3SettlementAmount': r3,
                            'diff': diff, 'beginningBalance': beginningBalance, 'fr': financialReadiness, 'selected': r
                        };
                        this.regionValuesNewR3.push(valObj);
                    }
                }

            }
        }
    }

}

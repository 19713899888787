import { Component, OnInit, ViewChild } from '@angular/core';
import { EventService } from 'src/app/services/event.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  
  eventTypes: any; 
  caseTypes: any; 
  events = new MatTableDataSource(); 
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;    
  displayedColumns: string[] = ['id', 'caseType', 'sourceSystem', 'subEventType','txnId','lastUpdated'];

  public constructor(
    private http: HttpClient,
    private eventService: EventService,
    private toastrService: ToastrService) {
    console.log('In EventsComponent constructor');
  }

  public ngOnInit(){
    this.events.sort = this.sort;
    this.events.paginator = this.paginator;
    this.getJSON().subscribe(data => {
      this.eventTypes = data;
    });
  }
  
  public getEventsData(eventType: string, subType: any, id: string): void {
    let pageNo = this.paginator.pageIndex;
    if(eventType === null || eventType === ''){
      alert('select event type');
    } if(eventType !== '' && subType !== '' && id !== ''){
      this.eventService.findByTypeAndSubtypeAndId(eventType, subType, id, pageNo).subscribe(data=>{
        this.events = new MatTableDataSource(data);
      }
      ,error => this.toastrService.error(error.error.errMessage, 'Error')
      );
    } else if(eventType !== '' && subType !== ''){
      this.eventService.findByTypeAndSubtype(eventType, subType, pageNo).subscribe(data=>{
        this.events = new MatTableDataSource(data);
      }
      ,error => this.toastrService.error(error.error.errMessage, 'Error')
      );
    } else if(eventType !== '' && id !== ''){
      this.eventService.findByTypeAndId(eventType, id, pageNo).subscribe(data=>{
        this.events = new MatTableDataSource(data);
      }
      ,error => this.toastrService.error(error.error.errMessage, 'Error')
      );
    } else {
        this.eventService.findByType(eventType, pageNo).subscribe(data=>{
          this.events = new MatTableDataSource(data);
        }
        ,error => this.toastrService.error(error.error.errMessage, 'Error')
        );
    }
  }

  public onEventChange(eventType: string): void {
    if(eventType === 'CASE_INSERT'){
      this.getCaseInsertTypes().subscribe(data => {
        this.caseTypes = data;
      });
    } else if(eventType === 'CASE_CLOSED'){
      this.getCaseClosedTypes().subscribe(data => {
        this.caseTypes = data;
      });
    } else {
      this.caseTypes = null;
    }
  }

  public getJSON(): Observable<any> {
    return this.http.get("./assets/events.json");
  }

  public getCaseInsertTypes(): Observable<any> {
    return this.http.get("./assets/caseInsertTypes.json");
  }

  public getCaseClosedTypes(): Observable<any> {
    return this.http.get("./assets/caseClosedTypes.json");
  }

 
}

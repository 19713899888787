import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DraftOptimizationService } from 'src/app/services/draftoptimization.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataService } from "src/app/services/data.service";
import { ErrorDialogComponent } from "src/app/alert/error-dialog.component";
import { MatDialog } from "@angular/material";

@Component({
    templateUrl: './draft-optimization.component.html',
    styleUrls: ['./draft-optimization.component.css']
})
export class DraftOptimizationComponent implements OnInit {

    draftForm: FormGroup;
    loading = false;
    draftModificationResponse: any;

    constructor(private draftOptimizationService: DraftOptimizationService,
        private toastrService: ToastrService,
        private formBuilder: FormBuilder) {
        this.draftForm = this.formBuilder.group({
            clientId: [null, Validators.required]
        });
    }

    public ngOnInit() {
        if (localStorage.getItem('clientId_draft') !== null) {
            this.draftForm.get('clientId').setValue(localStorage.getItem('clientId_draft'));
        }
    }

    public calculateDraftOptimization(settlement: any): void {
        this.loading = true;
        this.draftOptimizationService.calculateDraftOptimization(settlement.clientId).subscribe(response => {
            this.draftModificationResponse = response;
            this.renderResponse();
            this.loading = false;
        }
            , error => {
                this.loading = false;
                this.toastrService.error(error.error.errMessage, 'Error');
            }
        );
        localStorage.setItem('clientId_draft', settlement.clientId);
    }

    private renderResponse(): void {
    }
}
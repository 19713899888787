import {
    Directive,
    DoCheck,
    EmbeddedViewRef,
    Input,
    IterableChangeRecord,
    IterableChanges,
    IterableDiffer,
    IterableDiffers,
    NgIterable,
    OnChanges,
    SimpleChanges,
    TemplateRef,
    TrackByFunction,
    ViewContainerRef
} from '@angular/core';


export class CustomForOfContext<T> {
    constructor(
        public $implicit: T, public customForOf: NgIterable<T>, public index: number,
        public count: number) { }

    get first(): boolean { return this.index === 0; }

    get last(): boolean { return this.index === this.count - 1; }

    get even(): boolean { return this.index % 2 === 0; }

    get odd(): boolean { return !this.even; }
}


@Directive({ selector: '[customFor][customForOf]' })
export class CustomForOf<T> implements DoCheck, OnChanges {
    @Input() public customForOf: NgIterable<T>;

    @Input() public customForTrackBy: TrackByFunction<T>;

    private _differ: IterableDiffer<T> | null = null;

    constructor(
        private _viewContainer: ViewContainerRef,
        private _template: TemplateRef<CustomForOfContext<T>>,
        private _differs: IterableDiffers
    ) { }

    @Input()
    set customForTemplate(value: TemplateRef<CustomForOfContext<T>>) {
        if (value) {
            this._template = value;
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ('customForOf' in changes) {
            const value = changes.customForOf.currentValue;

            if (this._differ || !value) { return; }

            try {
                this._differ = this._differs.find(value).create(this.customForTrackBy);
            } catch (e) {
                throw new Error(
                    `Cannot find a differ supporting object '${value}' of type '${getTypeNameForDebugging(value)}'.`
                );
            }
        }
    }

    public ngDoCheck(): void {
        if (this._differ) {
            const changes = this._differ.diff(this.customForOf);

            if (changes) {
                this._applyChanges(changes);
            }
        }
    }

    private _applyChanges(changes: IterableChanges<T>): void {
        const viewContainerLength = this._viewContainer.length;
        const dataLength = (<any>this.customForOf).length;
        const tuples: any = {};

        changes.forEachOperation(
            (record: IterableChangeRecord<any>, _: number, currentIndex: number) => {
                if (currentIndex !== null) {
                    tuples[currentIndex] = record.item;
                }
            }
        );

        for (let i = viewContainerLength; i < dataLength; i++) {
            this._viewContainer.createEmbeddedView(this._template, new CustomForOfContext<T>(null!, this.customForOf, -1, -1), i);
        }

        for (let i = this._viewContainer.length; i > dataLength; i--) {
            this._viewContainer.remove(i);
        }

        for (let i = 0; i < this._viewContainer.length; i++) {
            const view = <EmbeddedViewRef<CustomForOfContext<T>>>this._viewContainer.get(i)!;
            view.context.index = i;
            view.context.count = length;
            view.context.$implicit = tuples[i] || null;
        }
    }
}


export function getTypeNameForDebugging(type: any): string {
    return type.name || typeof type;
}
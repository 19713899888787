import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from "@ngrx/store";
import { LOGOUT_ACTION } from "../store/user.login.actions";
import { LocalStorageService, STORAGE_KEYS } from "./localstorage.service";

@Injectable()
export class LogintimerService {

    private loginExpirationTimer: any;

    constructor(private http: HttpClient, private store: Store<{ person: { isLoggedIn: boolean } }>, private localStorage: LocalStorageService) {
        console.log('in Logintimer Service constructor');
    }

    //this is used for every 1 min
    setLogoutTimer(expirationDuration: number) {
        this.loginExpirationTimer = setInterval(() => {
            console.log("interval");

            let loginDetails = this.localStorage.readDataFromLocalStorage(STORAGE_KEYS.USER_LOGIN);
            let loginTime = loginDetails.loginTime;
            let currentTime = new Date().getTime();

            let diffInLoginTime = (currentTime - loginTime) / 60000;
            if (diffInLoginTime > 120) { // set 120 minutes
                let lastactive = +this.localStorage.readDataFromLocalStorage(STORAGE_KEYS.LAST_ACTIVITY);
                let diff = (currentTime - lastactive) / 1000;
                if (diff > 5) {  // set 5 mins
                    this.store.dispatch(new LOGOUT_ACTION(true));
                }
            }
        }, expirationDuration);
    }

    clearLogoutTimer() {
        if (this.loginExpirationTimer) {
            clearTimeout(this.loginExpirationTimer);
            this.loginExpirationTimer = null;
        }
    }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApplicationConstants } from './app.constants';
import { environment } from '../../environments/environment';

const apiURL = environment.SR_BASE_API_URL;
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'sourceId': environment.ROADMAP_UNDERWRITING_SECURITY_SOURCE_ID,
        'token': environment.ROADMAP_UNDERWRITING_SECURITY_TOKEN
    })
};
@Injectable()
export class RoadmapService {

    public settlementResponse: any;

    constructor(
        private router: Router,
        private http: HttpClient,
        private toastrService: ToastrService) {
        console.log('in RoadmapService constructor');
    }

    public buildRoadmap(settlement: any) {
        return this.http.post<any>(apiURL + ApplicationConstants.ROADMAP_UNDERWRITING_API_URL, settlement, httpOptions);
    }

}

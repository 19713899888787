import {Component} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { DWAService } from '../services/dwa.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { CommonService } from '../services/common.service';

@Component({
    templateUrl: './dwa.component.html',
    styleUrls: ['./dwa.component.css']
})
export class DWAComponent {
    
    public searching = false;
    public searchFailed = false;
    public responseStr: any;
    public dwaForm: FormGroup;
    public bucketMap: Map<String, Number>;
    public bucketMapKeys: any[];
    public pieChartLabels: string[] = [];
    public pieChartData: number[] = [];
    public pieChartType: string = 'pie';
 
    public constructor(
        private dwaService: DWAService,
        private toastrService: ToastrService, 
        private commonService: CommonService, 
        private formBuilder: FormBuilder) {
        this.dwaForm = this.formBuilder.group({
            creditor: [null, Validators.required]
        });
        if(localStorage.getItem('creditor') !== null){
            this.dwaForm.get('creditor').setValue(localStorage.getItem('creditor'));
        }
    }
    
    public getBucketDetails(obj: any): void {
        this.pieChartLabels = [];
        this.pieChartData = [];
        this.dwaService.getDataByCreditorName(obj.creditor.Name).subscribe(data=>{
            this.bucketMap = data.bucketMapCountMap;
            this.bucketMapKeys = Object.keys(this.bucketMap);
            if(this.bucketMapKeys.length === 0){
                alert('data not found');
            } else {
                for(let key of this.bucketMapKeys) {
                    this.pieChartLabels.push(key);
                    this.pieChartData.push(this.bucketMap[key]);
                    console.log(key +' == '+this.bucketMap[key]);
                }
            }
        }
        ,error => this.toastrService.error(error.error.errMessage+' Error')
        );
        localStorage.setItem('creditor', obj.creditorName);
    }

    public chartClicked(e:any):void {
        console.log(e);
    }
    
    public chartHovered(e:any):void {
        console.log(e);
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap(name =>
                this.commonService.searchSFCreditors(name).pipe(tap(() => this.searchFailed = false), catchError(() => {
                    this.searchFailed = true;
                    return of([]);
                }))
            ),
            tap(() => this.searching = false),
        )
    formatter = (x: { Name: string }) => x.Name;

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApplicationConstants } from './app.constants';
import { environment } from '../../environments/environment';

const apiURL = environment.SR_BASE_API_URL;
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'sourceId': environment.ROADMAP_SECURITY_SOURCE_ID,
        'token': environment.ROADMAP_SECURITY_TOKEN
    })
};
@Injectable()
export class DraftOptimizationService {

    public settlementResponse: any;

    constructor(
        private router: Router,
        private http: HttpClient,
        private toastrService: ToastrService) {
        console.log('in DraftOptimizationService constructor');
    }

    public calculateDraftOptimization(clientId: any) {
        return this.http.get<any>(apiURL + ApplicationConstants.DRAFT_OPTIMIZATION_API_URL + clientId, httpOptions);
    }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { EventService } from './services/event.service';
import { EventsComponent } from './event/list/events.component';
import { SRComponent } from './sr/sr.component';
import { AppRoutingModule } from './app.routing.module';
import { HttpClientModule } from '@angular/common/http';
import { DWAComponent } from './dwa/dwa.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { A11yModule } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { ChartsModule } from 'ng2-charts';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxLoadingModule } from 'ngx-loading';
import { CustomForOf } from '../custom-for.directive';

import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatInputModule,
  MatListModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
} from '@angular/material';
import { SRService } from './services/sr.service';
import { CommonService } from './services/common.service';
import { RoadmapService } from './services/roadmmap.service';
import { DataService } from './services/data.service';
import { RoadmapRequestComponent } from './roadmap/request/roadmap-request.component';
import { DWAService } from './services/dwa.service';
import { RoadmapResponseComponent } from './roadmap/response/roadmap-response.component';
import { ErrorDialogComponent } from './alert/error-dialog.component';
import { RoadmapUnderwritingComponent } from './roadmap/underwriting/roadmap-underwriting.component';
import { RoadmapUnderwritingService } from './services/underwriting.service';
import { FuseComponent } from './fuse/fuse.component';
import { FuseService } from './services/fuse.service';
import { DraftOptimizationComponent } from './draftoptimization/draft-optimization.component';
import { DraftOptimizationService } from './services/draftoptimization.service';
import { LogintimerService } from './services/logintimer.service';
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./guard/auth.guard";
import { StoreModule } from "@ngrx/store";
import { PersonEffects } from "./store/user.login.effects";
import { PersonReducer } from "./store/user.login.reducer";
import { EffectsModule } from "@ngrx/effects";
import { StorageServiceModule } from "ngx-webstorage-service";
import { LocalStorageService } from "./services/localstorage.service";


@NgModule({
  declarations: [
    AppComponent,
    EventsComponent,
    DWAComponent,
    SRComponent,
    RoadmapRequestComponent,
    RoadmapResponseComponent,
    RoadmapUnderwritingComponent,
    FuseComponent,
    DraftOptimizationComponent,
    ErrorDialogComponent,
    CustomForOf,
    LoginComponent
  ],
  entryComponents: [ErrorDialogComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgbModule.forRoot(),
    CommonModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    ChartsModule,
    StorageServiceModule,
    StoreModule.forRoot({ person: PersonReducer }),
    EffectsModule.forRoot([PersonEffects]),
    NgxLoadingModule.forRoot({})
  ],
  exports: [
    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    ScrollDispatchModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule
  ],
  providers: [
    CommonService,
    DWAService,
    EventService,
    SRService,
    DataService,
    RoadmapService,
    RoadmapUnderwritingService,
    FuseService,
    DraftOptimizationService,
    LogintimerService,
    AuthGuard,
    LocalStorageService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

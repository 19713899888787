import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { FuseService } from 'src/app/services/fuse.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataService } from "src/app/services/data.service";
import { ErrorDialogComponent } from "src/app/alert/error-dialog.component";
import { MatDialog } from "@angular/material";

@Component({
    templateUrl: './fuse.component.html',
    styleUrls: ['./fuse.component.css']
})
export class FuseComponent implements OnInit {

    fuseForm: FormGroup;
    loading = false;
    fuseScoreList: any;
    showTable = false;

    @Output() emit = new EventEmitter<any>();

    constructor(private fuseService: FuseService,
        private toastrService: ToastrService,
        private formBuilder: FormBuilder,
        private router: Router,
        private dataService: DataService,
        public dialog: MatDialog) {
        this.fuseForm = this.formBuilder.group({
            accountId: [null, Validators.required]
        });
    }

    ngOnInit() {
        if (localStorage.getItem('fuse_accountId') !== null) {
            this.fuseForm.get('accountId').setValue(localStorage.getItem('fuse_accountId'));
        }
        this.dataService.currentMessage.subscribe(message => this.fuseScoreList = message);
    }

    public getFuseData(input: any): void {
        this.loading = true;
        localStorage.setItem('fuse_accountId', input.accountId);

        this.fuseService.getFuseData(input.accountId).subscribe(response => {
            this.fuseScoreList = response;
            this.loading = false;
            this.showTable = true;

        }
            , error => {
                this.loading = false;
                this.toastrService.error(error.error.errMessage, 'Error');
                this.toastrService.error(error.error, 'Error');
                this.showError(error.error);
            }
        );
    }

    showError(error: string): void {
        this.dialog.open(ErrorDialogComponent, {
            data: { errorMsg: error }, width: '250px'
        });
    }

}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const apiURL = environment.SR_BASE_API_URL;

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'sourceId': environment.ROADMAP_SECURITY_SOURCE_ID,
        'token': environment.ROADMAP_SECURITY_TOKEN
    })
};
@Injectable()
export class SRService {

    constructor(private http: HttpClient) {
        console.log('in SRService constructor');
    }

    public calculateSR(request: any, amsType: string) {
        let amsString = '';
        let amsTypeParam = '';

        if (request.amsIds != null) {
            amsString = request.amsIds;
        }

        if (amsType != null) {
            amsTypeParam = '&amsType=' + amsType;
        }
        return this.http.post<any>(apiURL + '/v1/sr/clients/' + request.clientId + '?route=true&save=true&showResponse=true&supplementSync=false' + amsTypeParam, amsString, httpOptions);
    }

}

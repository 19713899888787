import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders } from '@angular/common/http';


const apiURL = 'https://fdr-apis-stg.appspot.com/events';
const httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Accept': '*/*'
  })
};
@Injectable()
export class EventService {

    constructor(private http:HttpClient) {}
  
    public findByTypeAndId(type: string, id : string, pageNo: number) {
      return this.http.get<any[]>(apiURL + '/type/' + type + '/id/' +id+'/'+pageNo ,httpOptions);
    }

    public findByType(type: string, pageNo: number) {
        return this.http.get<any[]>(apiURL + '/type/' + type + '/'+pageNo ,httpOptions);
    }

    public findByTypeAndSubtypeAndId(type: string, subType: string, id: string, pageNo: number) {
        return this.http.get<any[]>(apiURL + '/type/' + type + '/caseType/'+subType + '/id/'+ id +'/'+pageNo ,httpOptions);
    }

    public findByTypeAndSubtype(type: string, subType: string, pageNo: number) {
        return this.http.get<any[]>(apiURL + '/type/' + type + '/caseType/'+subType + '/' +pageNo ,httpOptions);
    }
 }
import { Component, OnInit } from '@angular/core';
import { SRService } from '../services/sr.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { MatExpansionModule } from '@angular/material/expansion';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { Roadmap } from '../../app/sr/sr.model';

@Component({
    templateUrl: './sr.component.html',
    styleUrls: ['./sr.component.css'],
    selector: 'keyvalue-pipe',
})
export class SRComponent implements OnInit {
    panelOpenState = false;

    srForm: FormGroup;
    settlementResponse: any;
    displayedColumns: string[] = ['monthYear', 'transactionType', 'amount', 'currentbalance', 'remark'];
    columnsList: string[] = ['monthYear', 'amount'];
    forecaster: any[];
    regionValuesNew: any[];
    accountsRankMap: Map<String, Number>;
    regionValues: Map<String, any>;
    lowestDifferntial: Map<String, Number>;
    firstSelectedAccount: Map<String, Number>;
    nextAccountsToSettle: Map<String, Number>;
    selectedAccount: Map<String, Number>;
    regionValuesKeys: any[];
    firstSelectionKey: any[];
    debtAccounts: any[];
    loading = false;
    amsType: string;

    constructor(private srService: SRService,
        private toastrService: ToastrService,
        private formBuilder: FormBuilder) {
        this.srForm = this.formBuilder.group({
            clientId: [null, Validators.required],
            amsIds: null
        });

        this.amsType = null;

        console.log('I am in constructor : ', environment.envName);
    }

    onChangeEventFunc(isChecked: any) {


        if (isChecked.checked == true) {

            this.amsType = 'matching_only'

        } else {

            console.log("What-If AMS - Unchecked");
        }
    }

    public ngOnInit() {
        if (localStorage.getItem('clientId') !== null) {
            this.srForm.get('clientId').setValue(localStorage.getItem('clientId'));
        }
    }

    public calculateSR(settlement: any): void {
        this.loading = true;
        this.srService.calculateSR(settlement, this.amsType).subscribe(response => {
            if (response.roadmapResponse != null) {

                this.settlementResponse = response.roadmapResponse;
                this.renderResponse();
            } else {

                var roadmap = new Roadmap;
                roadmap.remarks = "Roadmap eligible account not found for client id " + settlement.clientId;
                this.settlementResponse = roadmap;

            }

            this.loading = false;
        }
            , error => {
                this.loading = false;
                this.toastrService.error(error.error.errMessage, 'Error');
            }
        );
        localStorage.setItem('clientId', settlement.clientId);
    }

    private renderResponse(): void {
        var responseForecaster = this.settlementResponse.forecaster;
        try {
            if (responseForecaster != null) {
                this.forecaster = new Array();
                var index = 0;
                var start = 0;
                var end = 10;
                while (index < responseForecaster.length) {
                    if (index !== 0) {
                        start = index + 1;
                        end = index + 10;
                    }
                    console.log('start == ' + start + ' end == ' + end);
                    this.forecaster.push(responseForecaster.slice(index, end));
                    index += 10;
                }
                this.regionValuesNew = new Array();
                this.regionValuesKeys = new Array();

                //Region 1
                if (this.settlementResponse.regionDetail.regionOne != undefined) {
                    this.accountsRankMap = this.settlementResponse.regionDetail.regionOne.accountRanks;
                    this.regionValues = this.settlementResponse.regionDetail.regionOne.regionValues;
                    this.lowestDifferntial = this.settlementResponse.regionDetail.regionOne.lowestDifferntial;
                    this.firstSelectedAccount = this.settlementResponse.regionDetail.regionOne.firstSelectedAccount;
                    this.nextAccountsToSettle = this.settlementResponse.regionDetail.regionOne.nextAccountsToSettle;
                    this.debtAccounts = this.settlementResponse.debtAccounts;

                    if (Object.keys(this.firstSelectedAccount).length > 0) {
                        this.regionValuesKeys.push(Object.keys(this.firstSelectedAccount));
                    }
                    if (Object.keys(this.nextAccountsToSettle).length > 0) {
                        this.regionValuesKeys.push(Object.keys(this.nextAccountsToSettle));
                    }

                    if (this.regionValuesKeys.length > 0) {
                        this.regionValuesKeys.forEach(accountId => {
                            accountId.forEach(acc => {
                                var account = this.debtAccounts.find(x => x.accountId === acc);
                                if (account != undefined && account.regionOneAvailableToSettle) {
                                    var valObj = new Object;
                                    var r1;
                                    var r2;
                                    var r3;
                                    var rank;
                                    var beginningBalance;
                                    var financialReadiness;
                                    var region = 'region1';
                                    r1 = account.r1SettlementAmount;
                                    r1 = r1.toFixed(2);
                                    r2 = account.r2SettlementAmount;
                                    r2 = r2.toFixed(2);
                                    r3 = account.r3SettlementAmount;
                                    r3 = r3.toFixed(2);
                                    var r = this.firstSelectedAccount[account.accountId] != null;
                                    if (r === undefined) {
                                        r = this.nextAccountsToSettle[account.accountId] != null;
                                    }
                                    var diff = this.lowestDifferntial[account.accountId];
                                    diff = diff.toFixed(2);
                                    rank = account.regionOneCreditorRank;
                                    beginningBalance = account.beginningBalance;
                                    beginningBalance = beginningBalance.toFixed(2);
                                    financialReadiness = account.financialReadiness;
                                    valObj = {
                                        'accountId': account.accountId, 'rank': rank, 'r1SettlementAmount': r1, 'r2SettlementAmount': r2, 'r3SettlementAmount': r3,
                                        'diff': diff, 'beginningBalance': beginningBalance, 'fr': financialReadiness, 'selected': r, 'region': region
                                    };
                                    this.regionValuesNew.push(valObj);
                                }
                            });
                        });
                    }
                }

                //Region 2
                if (this.settlementResponse.regionDetail.regionTwo != undefined) {
                    this.accountsRankMap = this.settlementResponse.regionDetail.regionTwo.accountRanks;
                    this.regionValues = this.settlementResponse.regionDetail.regionTwo.regionValues;
                    this.lowestDifferntial = this.settlementResponse.regionDetail.regionTwo.lowestDifferntial;
                    this.selectedAccount = this.settlementResponse.regionDetail.regionTwo.selectedAccount;
                    this.debtAccounts = this.settlementResponse.debtAccounts;
                    this.regionValuesKeys = Object.keys(this.selectedAccount);
                    var region = 'region2';
                    if (this.regionValuesNew.length == 0) {
                        this.regionValuesNew = new Array();
                    }

                    for (let key of this.regionValuesKeys) {
                        var account = this.debtAccounts.filter(x => x.accountId === key);
                        if (account != undefined && account.length > 0 && account[0].regionTwoAvailableToSettle) {
                            var valObj = new Object;
                            var r2;
                            var r3;
                            var rank;
                            var beginningBalance;
                            var financialReadiness;
                            r2 = account[0].r2SettlementAmount;
                            r2 = r2.toFixed(2);
                            r3 = account[0].r3SettlementAmount;
                            r3 = r3.toFixed(2);
                            var r = this.selectedAccount[account[0].accountId] != null;
                            var diff = this.lowestDifferntial[account[0].accountId];
                            diff = diff.toFixed(2);
                            rank = account[0].regionTwoCreditorRank;
                            beginningBalance = account[0].beginningBalance;
                            beginningBalance = beginningBalance.toFixed(2);
                            financialReadiness = account[0].financialReadiness;
                            valObj = {
                                'accountId': account[0].accountId, 'rank': rank, 'r2SettlementAmount': r2, 'r3SettlementAmount': r3,
                                'diff': diff, 'beginningBalance': beginningBalance, 'fr': financialReadiness, 'selected': r, 'region': region
                            };
                            this.regionValuesNew.push(valObj);
                        }
                    }
                }

                //Region 3
                if (this.settlementResponse.regionDetail.regionThree != undefined) {
                    this.lowestDifferntial = this.settlementResponse.regionDetail.regionThree.lowestDifferential;
                    this.selectedAccount = this.settlementResponse.regionDetail.regionThree.selectedAccount;
                    this.debtAccounts = this.settlementResponse.debtAccounts;
                    var region = 'region3';
                    if (this.regionValuesNew.length == 0) {
                        this.regionValuesNew = new Array();
                    }
                    this.regionValuesKeys = Object.keys(this.selectedAccount);
                    for (let key of this.regionValuesKeys) {
                        var account = this.debtAccounts.filter(x => x.accountId === key);
                        if (account != undefined && account.length > 0 && account[0].regionThreeAvailableToSettle) {
                            var valObj = new Object;
                            var r2;
                            var r3;
                            var rank;
                            var beginningBalance;
                            var financialReadiness;
                            var r3FirstSettlementAmount;
                            var r3NextSettlementAmount;
                            r3FirstSettlementAmount = account[0].r3FirstSettlementAmount;
                            r3FirstSettlementAmount = r3FirstSettlementAmount.toFixed(2);
                            r3NextSettlementAmount = account[0].r3NextSettlementAmount;
                            r3NextSettlementAmount = r3NextSettlementAmount.toFixed(2);
                            var r = this.selectedAccount[account[0].accountId] != null;
                            var diff = this.lowestDifferntial[account[0].accountId];
                            diff = diff.toFixed(2);
                            rank = account[0].regionThreeCreditorRank;
                            beginningBalance = account[0].beginningBalance;
                            beginningBalance = beginningBalance.toFixed(2);
                            financialReadiness = account[0].financialReadiness;
                            valObj = {
                                'accountId': account[0].accountId, 'rank': rank, 'r3FirstSettlementAmount': r3FirstSettlementAmount, 'r3NextSettlementAmount': r3NextSettlementAmount,
                                'diff': diff, 'beginningBalance': beginningBalance, 'fr': financialReadiness, 'selected': r, 'region': region
                            };
                            this.regionValuesNew.push(valObj);
                        }
                    }

                }

                //Region 4
                if (this.settlementResponse.regionDetail.regionFour != undefined) {
                    this.selectedAccount = this.settlementResponse.regionDetail.regionFour.selectedAccount;
                    this.debtAccounts = this.settlementResponse.debtAccounts;
                    var region = 'region4';
                    if (this.regionValuesNew.length == 0) {
                        this.regionValuesNew = new Array();
                    }
                    this.regionValuesKeys = Object.keys(this.selectedAccount);
                    for (let key of this.regionValuesKeys) {
                        var account = this.debtAccounts.filter(x => x.accountId === key);
                        if (account != undefined && account.length > 0 && account[0].regionFourAvailableToSettle) {
                            var valObj = new Object;
                            var rank;
                            var beginningBalance;
                            var financialReadiness;
                            var r4 = account[0].r4SettlementAmount;
                            const diff = 0.0;
                            rank = account[0].regionFourCreditorRank;
                            beginningBalance = account[0].beginningBalance;
                            beginningBalance = beginningBalance.toFixed(2);
                            financialReadiness = account[0].financialReadiness;
                            var r = this.selectedAccount[account[0].accountId] != null;
                            valObj = {
                                'accountId': account[0].accountId, 'rank': rank, 'r4SettlementAmount': r4, 'diff': diff,
                                'beginningBalance': beginningBalance, 'fr': financialReadiness, 'selected': r, 'region': region
                            };
                            this.regionValuesNew.push(valObj);
                        }
                    }

                }

            }
        } catch (error) {
            this.loading = false;
            throw new Error(error.message);
        }
    }
}

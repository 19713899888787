import { Action } from '@ngrx/store';

export const LOGIN = "[Person] Login";
export const LOGOUT = "[Person] Logout";
export const LOGIN_SUCCESS = "[Person] Login Success";
export const LOGIN_FAILURE = "[Person] Login Failure";
export const REDIRECT_HOME = "[REDIRECT] Home";
export const AUTO_LOGIN = "[AUTO_LOGIN] Auto Login";
export const AUTO_LOGIN_SUCCESS = "[AUTO_LOGIN] Auto Login Success";

export class LOGIN_ACTION implements Action {
    readonly type = LOGIN;
    constructor(public userData: { username: string, password: string }) {
    }
}

export class LOGOUT_ACTION implements Action {
    readonly type = LOGOUT;
    constructor(public forcedToLogout: boolean) {
    }
}

export class LOGIN_SUCCESS_ACTION implements Action {
    readonly type = LOGIN_SUCCESS;
    constructor({ authenticated: boolean }) {
    }
}

export class LOGIN_FAILURE_ACTION implements Action {
    readonly type = LOGIN_FAILURE;
    constructor({ authenticated: boolean }) {
    }
}

export class REDIRECT_HOME_ACTION implements Action {
    readonly type = REDIRECT_HOME;
    constructor() {
    }
}

export class AUTO_LOGIN_ACTION implements Action {
    readonly type = AUTO_LOGIN;
    constructor(public userdata: { username: string, isLoggedIn: boolean }) {
    }
}

export class AUTO_LOGIN_ACTION_SUCCESS implements Action {
    readonly type = AUTO_LOGIN_SUCCESS;
}

export type PersonActions = LOGIN_SUCCESS_ACTION | LOGIN_FAILURE_ACTION | REDIRECT_HOME_ACTION | LOGIN_ACTION | LOGOUT_ACTION | AUTO_LOGIN_ACTION




import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { RoadmapUnderwritingService } from 'src/app/services/underwriting.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataService } from "src/app/services/data.service";
import { RoadmapService } from 'src/app/services/roadmmap.service';
import { ErrorDialogComponent } from "src/app/alert/error-dialog.component";
import { MatDialog } from "@angular/material";

@Component({
    templateUrl: './roadmap-underwriting.component.html',
    styleUrls: ['./roadmap-underwriting.component.css']
})
export class RoadmapUnderwritingComponent implements OnInit {

    underwritingForm: FormGroup;
    loading = false;
    settlementResponse: any;
    result: any;
    public transactionInfoResponse: any;
    public summaries: any[];
    transaction: any;
    public client: any;
    public selectedTxn: any;

    @Output() emit = new EventEmitter<any>();

    constructor(private underwritingService: RoadmapUnderwritingService,
        private toastrService: ToastrService,
        private formBuilder: FormBuilder,
        private router: Router,
        private dataService: DataService,
        private roadmapService: RoadmapService,
        public dialog: MatDialog) {
        this.underwritingForm = this.formBuilder.group({
            accountId: [null, Validators.required]
        });
    }

    ngOnInit() {
        if (localStorage.getItem('accountId') !== null) {
            this.underwritingForm.get('accountId').setValue(localStorage.getItem('accountId'));
        }
        this.dataService.currentMessage.subscribe(message => this.settlementResponse = message);
    }

    public getTransactionInfo(settlement: any): void {
        this.loading = true;

        this.underwritingService.getTransactionInfo(settlement.accountId).subscribe(response => {
            this.transactionInfoResponse = response;
            this.client = this.transactionInfoResponse[0].clientName;
            this.selectedTxn = this.transactionInfoResponse[0].transactionId;
            this.loading = false;

        }
            , error => {
                this.loading = false;
                this.toastrService.error(error.error.errMessage, 'Error');
                this.toastrService.error(error.error, 'Error');
                this.showError(error.error);
            }
        );

        localStorage.setItem('accountId', settlement.accountId);
    }

    selectTxnId(name: string) {
        let obj = this.transactionInfoResponse.filter(m => m.transactionId == name);
        this.transaction = obj[0].transactionId;
        this.client = obj[0].clientName;
        return this.transaction;
    }

    public analysizeUnderwritingRoadmap(): void {
        this.loading = true;
        if (this.transaction == undefined) {
            this.transaction = this.selectedTxn;
        }
        this.underwritingService.analysizeUnderwritingRoadmap(this.transaction).subscribe(response => {
            this.settlementResponse = response;
            this.dataService.changeMessage(response);

            this.roadmapService.buildRoadmap(this.settlementResponse).subscribe(data => {
                this.result = data;
                this.dataService.changeMessage(data);
                this.router.navigateByUrl('roadmap/response');
                this.loading = false;
            }
                , error => {
                    this.loading = false;
                    this.toastrService.error(error.error.errMessage, 'Error');
                    this.toastrService.error(error.error, 'Error');
                    this.showError(error.error);
                });

        }
            , error => {
                this.loading = false;
                this.toastrService.error(error.error.errMessage, 'Error');
                this.toastrService.error(error.error, 'Error');
                this.showError(error.error);
            }
        );

    }

    showError(error: string): void {
        this.dialog.open(ErrorDialogComponent, {
            data: { errorMsg: error }, width: '250px'
        });
    }

}
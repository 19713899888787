import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventsComponent } from './event/list/events.component';
import { DWAComponent } from './dwa/dwa.component';
import { SRComponent } from './sr/sr.component';
import { RoadmapRequestComponent } from './roadmap/request/roadmap-request.component';
import { RoadmapResponseComponent } from './roadmap/response/roadmap-response.component';
import { RoadmapUnderwritingComponent } from './roadmap/underwriting/roadmap-underwriting.component';
import { FuseComponent } from './fuse/fuse.component';
import { DraftOptimizationComponent } from './draftoptimization/draft-optimization.component';
import { AuthGuard } from "./guard/auth.guard";
import { LoginComponent } from "./login/login.component";

const routes: Routes = [
    { path: 'fdr/events', component: EventsComponent, canActivate: [AuthGuard] },
    { path: 'bucket/count', component: DWAComponent, canActivate: [AuthGuard] },
    { path: 'sr', component: SRComponent, canActivate: [AuthGuard] },
    { path: 'roadmap/request', component: RoadmapRequestComponent, canActivate: [AuthGuard] },
    { path: 'roadmap/response', component: RoadmapResponseComponent, canActivate: [AuthGuard] },
    { path: 'roadmap/underwriting', component: RoadmapUnderwritingComponent, canActivate: [AuthGuard] },
    { path: 'fuse', component: FuseComponent, canActivate: [AuthGuard] },
    { path: 'draft-optimization', component: DraftOptimizationComponent, canActivate: [AuthGuard] },
    { path: '', component: LoginComponent, pathMatch: 'full' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class AppRoutingModule { }

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

const apiURL = 'https://negotiator-dot-fdr-forecasting-stg.appspot.com/v1';
@Injectable()
export class DWAService {

    constructor(private http: HttpClient) {
        console.log('in DWAService constructor');
    }
	
	public getDataByCreditorName(creditorName : string) {
        return this.http.get<any>(apiURL+'/accounts/dwa/bucketCount/byName?creditorName=' + creditorName);
    }

}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { AUTO_LOGIN_ACTION } from "../store/user.login.actions";
import { LocalStorageService, STORAGE_KEYS } from "../services/localstorage.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
        private store: Store<{ person: { isLoggedIn: boolean } }>, private localStorageService: LocalStorageService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        let localData: { username: string, isLoggedIn: boolean, loginTime: number } = this.localStorageService.readDataFromLocalStorage(STORAGE_KEYS.USER_LOGIN);
        if (localData != undefined) {
            this.store.dispatch(new AUTO_LOGIN_ACTION(localData));
        }

        return this.store.select("person").pipe(
            map((person) => {
                if (person.isLoggedIn) {
                    return true;
                }
                this.router.navigate([""]);
                return false;
            })
        );
    }
}

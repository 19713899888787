import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, switchMap, tap } from "rxjs/operators";
import {
    LOGIN,
    LOGIN_SUCCESS_ACTION,
    LOGIN_FAILURE_ACTION,
    LOGIN_ACTION,
    LOGOUT,
    AUTO_LOGIN,
    LOGIN_SUCCESS
} from "./user.login.actions";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { LocalStorageService, STORAGE_KEYS } from "../services/localstorage.service";
import { LogintimerService } from "../services/logintimer.service";
import { environment } from "../../environments/environment";


const apiURL = environment.AUTH_BASE_API_URL;

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*'
    })
};

@Injectable({
    providedIn: "root"
})
export class PersonEffects {

    constructor(private actions$: Actions,
        private http: HttpClient,
        private router: Router, private localStorageService: LocalStorageService, private loginService: LogintimerService) {
    }

    @Effect()
    login = this.actions$.pipe(
        ofType(LOGIN),
        switchMap((loginaction: LOGIN_ACTION) => {
            return this.http.post<any[]>(apiURL, {
                'username': loginaction.userData.username, 'password': loginaction.userData.password
            }, httpOptions)
                .pipe(
                map(resData => {
                    let username = loginaction.userData.username;
                    let currentTime = new Date().getTime();
                    this.localStorageService.storeOnLocalStorage(STORAGE_KEYS.USER_LOGIN, { username: username, isLoggedIn: true, loginTime: currentTime });
                    this.loginService.setLogoutTimer(1000 * 60); // set 1 min
                    return new LOGIN_SUCCESS_ACTION({ authenticated: true });
                }),
                catchError(errorRes => {
                    return of(new LOGIN_FAILURE_ACTION({ authenticated: false }));
                })
                );
        })
    );

    @Effect({ dispatch: false })
    autoLoginRedirect = this.actions$.pipe(
        ofType(AUTO_LOGIN),
        tap(() => {
            this.loginService.setLogoutTimer(1000 * 60);
        })
    );

    @Effect({ dispatch: false })
    redirect = this.actions$.pipe(
        ofType(LOGIN_SUCCESS),
        tap(() => {
            this.router.navigate(['/sr']);
        })
    );

    @Effect({ dispatch: false })
    authRedirect = this.actions$.pipe(
        ofType(LOGOUT),
        tap(() => {
            this.localStorageService.clearDataFromLocalStorage();
            this.loginService.clearLogoutTimer(); // clear timer
            this.router.navigate(['']);
        })
    );

}

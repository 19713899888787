import {Component, HostListener, OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';
import {Store} from "@ngrx/store";
import {AUTO_LOGIN_ACTION, LOGOUT_ACTION} from "./store/user.login.actions";
import {Observable} from "rxjs";
import {LocalStorageService, STORAGE_KEYS} from "./services/localstorage.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  person: Observable<any>;

  @HostListener('document:mousemove',['$event'])
  onMouseMove(e){
    this.localStorageService.storeOnLocalStorage(STORAGE_KEYS.LAST_ACTIVITY, new Date().getTime());
  }
  constructor(private store: Store<{person: { isLoggedIn: boolean }}>,
              private localStorageService : LocalStorageService) {
  }

  ngOnInit(): void {
    let localData: {username: string, isLoggedIn: boolean,loginTime: number} = this.localStorageService.readDataFromLocalStorage(STORAGE_KEYS.USER_LOGIN);
    if(localData != undefined) {
      this.store.dispatch(new AUTO_LOGIN_ACTION(localData));
    }
    this.person = this.store.select('person');
  }

  onLogout() {
    this.store.dispatch(new LOGOUT_ACTION(false));
  }

}

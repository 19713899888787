export const environment = {
  production: true,
  envName: 'stg',
  ROADMAP_UNDERWRITING_SECURITY_SOURCE_ID: '9910v00000LQI1yBBz',
  ROADMAP_UNDERWRITING_SECURITY_TOKEN: 'tZxCyNIXfa2h8R4sN7k6J7O8GvDEnghRJrMRW2ifO7jTM7u1GMNwl3YbsTl4xAee7AMhHC9fX07tSdvmz2HQ5sZ6ZeyqHz4Gb5Z8',
  SR_BASE_API_URL: 'https://crm-settlement-readiness.stg.fdrgcp.com',
  ROADMAP_SECURITY_SOURCE_ID: '9910v00000LQI1yBBz',
  ROADMAP_SECURITY_TOKEN: 'tZxCyNIXfa2h8R4sN7k6J7O8GvDEnghRJrMRW2ifO7jTM7u1GMNwl3YbsTl4xAee7AMhHC9fX07tSdvmz2HQ5sZ6ZeyqHz4Gb5Z8',
  AUTH_BASE_API_URL: 'https://crm-sif-api.stg.fdrgcp.com/v1/auth'
};

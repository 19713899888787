import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap, switchMap, catchError } from 'rxjs/operators';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from 'src/app/utility/ngb-date-fr-parser-formatter';
import { RoadmapService } from 'src/app/services/roadmmap.service';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { DataService } from "src/app/services/data.service";
import { ErrorDialogComponent } from "src/app/alert/error-dialog.component";
import { MatDialog } from "@angular/material";

@Component({
    templateUrl: './roadmap-request.component.html',
    styleUrls: ['./roadmap-request.component.css'],
    providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }]
})
export class RoadmapRequestComponent implements OnInit {

    panelOpenState = true;
    loading = false;
    settlement: any;
    roadmapForm: FormGroup;
    searching = false;
    searchFailed = false;
    debtAccounts;
    creditor: any;
    accounts: any[] = [];
    account: any;
    model: NgbDateStruct;
    date: { year: number, month: number, day: number };

    @Output() emit = new EventEmitter<any>();

    public settlementResponse: any;

    public constructor(
        private router: Router,
        private roadmapService: RoadmapService,
        private commonService: CommonService,
        private formBuilder: FormBuilder,
        private ngbDateParserFormatter: NgbDateParserFormatter,
        private toastrService: ToastrService,
        private dataService: DataService,
        public dialog: MatDialog) {
        this.roadmapForm = this.formBuilder.group({
            clientId: [null, Validators.required],
            clientName: [null, Validators.required],
            clientState: [null],
            draftAmount: [null, Validators.required],
            feePercentage: [null, Validators.required],
            spaCurrentBalance: [null],
            fixedMonthlyFees: [0.0],
            settlementSource: [null],
            debtAccounts: this.formBuilder.array([])
        });
    }

    ngOnInit() {
        //this.getSavedData();
        this.getSavedClientData();
        this.dataService.currentMessage.subscribe(message => this.settlementResponse = message);
    }

    createAccount(): FormGroup {
        return this.formBuilder.group({
            id: [null, Validators.required],
            lastPaymentDate: [null, Validators.required],
            currentCreditor: [null, Validators.required],
            originalCreditor: [null],
            beginingBalance: [null, Validators.required],
            currentBalance: [null]
        });
    }

    addAccount(): void {
        this.debtAccounts = this.formData();
        this.debtAccounts.push(this.createAccount());
    }

    addDefaultAccounts(): void {
        this.debtAccounts = this.formData();
        let accountList = this.getAccountDetails();
        for (let i = 0; i < accountList.length; i++) {
            this.debtAccounts.push(this.getAccount(accountList[i]));
        }
    }

    getAccountDetails() {
        let accountList = [];
        let currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1);
        console.log("Current date ", JSON.stringify(currentDate));
        const lpd = {
            year: currentDate.getFullYear(), month: currentDate.getMonth() != 12 ? currentDate.getMonth() + 1 : currentDate.getMonth()
            , day: currentDate.getDate()
        };
        let account1: any;
        account1 = {};
        let currentCreditor1: any;
        currentCreditor1 = {};
        currentCreditor1.Id = "a21700000047JA9AAM";
        currentCreditor1.Name = "Capital One";
        account1.id = "account1";
        account1.lastPaymentDate = lpd;
        account1.currentCreditor = currentCreditor1;
        account1.beginingBalance = 7000;
        accountList.push(account1);

        let account2: any;
        account2 = {};
        let currentCreditor2: any;
        currentCreditor2 = {};
        currentCreditor2.Id = "a21700000047J8rAAE";
        currentCreditor2.Name = "Bank of America";
        account2.id = "account2";
        account2.lastPaymentDate = lpd;
        account2.currentCreditor = currentCreditor2;
        account2.beginingBalance = 5500;
        accountList.push(account2);

        let account3: any;
        account3 = {};
        let currentCreditor3: any;
        currentCreditor3 = {};
        currentCreditor3.Id = "a21700000047J8wAAE";
        currentCreditor3.Name = "Chase";
        account3.id = "account3";
        account3.lastPaymentDate = lpd;
        account3.currentCreditor = currentCreditor3;
        account3.beginingBalance = 5000;
        accountList.push(account3);

        let account4: any;
        account4 = {};
        let currentCreditor4: any;
        currentCreditor4 = {};
        currentCreditor4.Id = "a21700000047J7YAAU";
        currentCreditor4.Name = "Synchrony Bank";
        account4.id = "account4";
        account4.lastPaymentDate = lpd;
        account4.currentCreditor = currentCreditor4;
        account4.beginingBalance = 8000;
        accountList.push(account4);

        let account5: any;
        account5 = {};
        let currentCreditor5: any;
        currentCreditor5 = {};
        currentCreditor5.Id = "a21700000047J9IAAU";
        currentCreditor5.Name = "Wells Fargo";
        account5.id = "account5";
        account5.lastPaymentDate = lpd;
        account5.currentCreditor = currentCreditor5;
        account5.beginingBalance = 5000;
        accountList.push(account5);
        return accountList;

    }

    deleteAccount(event): void {
        var index = parseInt(event.currentTarget.id);
        var control = <FormArray>this.roadmapForm.controls['debtAccounts'];
        control.removeAt(index);
    }

    formData() { return <FormArray>this.roadmapForm.get('debtAccounts'); }

    buildRoadmap(settlement: any): void {
        localStorage.setItem('settlement', JSON.stringify(settlement));
        for (let debtAccount of settlement.debtAccounts) {
            this.account = {};
            this.account.id = debtAccount.id;
            this.account.lastPaymentDate = this.ngbDateParserFormatter.format(JSON.parse(JSON.stringify(debtAccount.lastPaymentDate)));
            this.account.currentCreditorId = debtAccount.currentCreditor.Id;
            this.account.originalCreditorId = debtAccount.originalCreditor != null ? debtAccount.originalCreditor.Id : null;
            this.account.beginingBalance = debtAccount.beginingBalance;
            this.account.currentBalance = debtAccount.currentBalance;
            this.accounts.push(this.account);
        }
        settlement.debtAccounts = this.accounts;
        this.loading = true;

        this.roadmapService.buildRoadmap(settlement).subscribe(data => {
            this.settlementResponse = data;
            this.dataService.changeMessage(data)
            this.router.navigateByUrl('roadmap/response');
        }
            , error => {
                this.loading = false;
                this.toastrService.error(error.error, 'Error');
                this.showError(error.error);
            }
        );
        //this.loading = false;
    }

    showError(error: string): void {
        this.dialog.open(ErrorDialogComponent, {
            data: { errorMsg: error }, width: '250px'
        });
    }

    getSavedClientData(): void {
        let i = 0;
        if (localStorage.getItem('settlement') !== null) {
            this.settlement = JSON.parse(localStorage.getItem('settlement'));
            this.roadmapForm.get('clientId').setValue(this.settlement.clientId);
            this.roadmapForm.get('clientName').setValue(this.settlement.clientName);
            this.roadmapForm.get('clientState').setValue(this.settlement.clientState);
            this.roadmapForm.get('draftAmount').setValue(this.settlement.draftAmount);
            this.roadmapForm.get('feePercentage').setValue(this.settlement.feePercentage);
            this.roadmapForm.get('spaCurrentBalance').setValue(this.settlement.spaCurrentBalance);
            this.roadmapForm.get('fixedMonthlyFees').setValue(this.settlement.fixedMonthlyFees);
            this.roadmapForm.get('settlementSource').setValue(this.settlement.settlementSource);

        }
    }

    getSavedData(): void {
        let i = 0;
        if (localStorage.getItem('settlement') !== null) {
            this.settlement = JSON.parse(localStorage.getItem('settlement'));
            this.roadmapForm.get('clientId').setValue(this.settlement.clientId);
            this.roadmapForm.get('clientName').setValue(this.settlement.clientName);
            this.roadmapForm.get('clientState').setValue(this.settlement.clientState);
            this.roadmapForm.get('draftAmount').setValue(this.settlement.draftAmount);
            this.roadmapForm.get('feePercentage').setValue(this.settlement.feePercentage);
            this.roadmapForm.get('spaCurrentBalance').setValue(this.settlement.spaCurrentBalance);
            this.roadmapForm.get('fixedMonthlyFees').setValue(this.settlement.fixedMonthlyFees);
            this.roadmapForm.get('settlementSource').setValue(this.settlement.settlementSource);
            this.debtAccounts = this.formData();
            this.account = this.settlement.debtAccounts[0];
            this.debtAccounts.controls[0].get('id').setValue(this.account.id);
            this.debtAccounts.controls[0].get('lastPaymentDate').setValue(this.account.lastPaymentDate);
            this.debtAccounts.controls[0].get('currentCreditor').setValue(this.account.currentCreditor);
            this.debtAccounts.controls[0].get('originalCreditor').setValue(this.account.originalCreditor);
            this.debtAccounts.controls[0].get('beginingBalance').setValue(this.account.beginingBalance);
            this.debtAccounts.controls[0].get('currentBalance').setValue(this.account.currentBalance);
            for (i = 1; i < this.settlement.debtAccounts.length; i++) {
                this.debtAccounts.push(this.getAccount(this.settlement.debtAccounts[i]));
            }
        }
    }

    getAccount(account: any): FormGroup {
        return this.formBuilder.group({
            id: [account.id],
            lastPaymentDate: [account.lastPaymentDate],
            currentCreditor: [account.currentCreditor],
            originalCreditor: [account.originalCreditor],
            beginingBalance: [account.beginingBalance],
            currentBalance: [account.currentBalance]
        });
    }

    convertToDate(lastPaymentDate: string): NgbDateStruct {
        var date1 = new Date(lastPaymentDate);
        return this.model = { year: date1.getFullYear(), month: date1.getMonth() + 1, day: date1.getDate() };
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap(name =>
                this.commonService.searchSFCreditors(name).pipe(tap(() =>
                    this.searchFailed = false),
                    catchError(() => {
                        this.searchFailed = true;
                        return of([]);
                    }))
            ),
            tap(() => this.searching = false),
        )
    formatter = (x: { Name: string }) => x.Name;

}

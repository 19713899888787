import { AUTO_LOGIN, LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT, PersonActions, } from "./user.login.actions";

interface State {
    isLoggedIn: boolean;
    username: string;
    isServiceFailedToLogin: boolean;
    forcedLogout: boolean;
}
const initialState: State = {
    isLoggedIn: false,
    username: '',
    isServiceFailedToLogin: false,
    forcedLogout: false
}

export function PersonReducer(
    state = initialState,
    action: PersonActions
) {
    switch (action.type) {
        case LOGOUT:
            return Object.assign({}, initialState, {
                forcedLogout: action.forcedToLogout
            });
        case LOGIN:
            return {
                ...state,
                username: action.userData.username
            }
        case AUTO_LOGIN:
            return {
                ...state,
                isLoggedIn: action.userdata.isLoggedIn,
                username: action.userdata.username,
                isServiceFailedToLogin: false
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                isLoggedIn: false,
                isServiceFailedToLogin: true
            };
        default:
            return state;
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const apiURL = environment.SR_BASE_API_URL;
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'sourceId': environment.ROADMAP_SECURITY_SOURCE_ID,
        'token': environment.ROADMAP_SECURITY_TOKEN
    })
};
@Injectable()
export class CommonService {

    constructor(private http: HttpClient) {
        console.log('in CommonService constructor');
    }

    public searchSFCreditors(name: string) {
        if (name !== null) {
            return this.http.get<any[]>(apiURL + '/v1/creditors/name?name=' + name, httpOptions);
        }
    }

    public getSFCreditorComboByCreditorId(creditorId: string) {
        return this.http.get<any[]>(apiURL + '/v1/creditors/' + creditorId + '/creditorCombos', httpOptions);
    }

}

import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

export enum STORAGE_KEYS {
    USER_LOGIN = "user_login",
    LAST_ACTIVITY = "last_activity"
}
@Injectable()
export class LocalStorageService {

    constructor( @Inject(LOCAL_STORAGE) private storage: StorageService) {
    }

    public storeOnLocalStorage(key: string, storageData: any): void {
        this.storage.set(key, storageData);
    }

    public readDataFromLocalStorage(key) {
        if (this.storage.has(key)) {
            return this.storage.get(key);
        }
    }

    public clearDataFromLocalStorage() {
        localStorage.clear();
    }
}
